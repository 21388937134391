var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _c("vue-headful", { attrs: { title: _vm.appName + " - Dashboard" } }),
      _c(
        "gov-main-wrapper",
        [
          _c(
            "gov-grid-row",
            [
              _c(
                "gov-grid-column",
                { attrs: { width: "two-thirds" } },
                [
                  _c("gov-heading", { attrs: { size: "xl" } }, [
                    _vm._v(
                      "\n          Welcome to the " +
                        _vm._s(_vm.appName) +
                        " admin portal\n        "
                    )
                  ]),
                  _c(
                    "gov-body",
                    { attrs: { size: "l" } },
                    [
                      _vm._v(
                        "\n          From here, you can add and edit your pages on " +
                          _vm._s(_vm.appName) +
                          ", as well\n          as manage referrals into your service. For any support, contact\n          "
                      ),
                      _c(
                        "gov-link",
                        { attrs: { href: "mailto:" + _vm.contactEmail } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.contactEmail) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm.appHelpSectionActive
                        ? _c(
                            "span",
                            [
                              _vm._v("\n            or view our\n            "),
                              _c(
                                "gov-link",
                                { attrs: { to: { name: "help-index" } } },
                                [_vm._v("help videos")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("gov-section-break", { attrs: { size: "l" } }),
          _c(
            "gov-grid-row",
            [
              _vm.auth.canView("services")
                ? _c(
                    "gov-grid-column",
                    { attrs: { width: "one-half" } },
                    [
                      _c("gov-heading", { attrs: { size: "l" } }, [
                        _vm._v("Services")
                      ]),
                      _c("gov-body", [
                        _vm._v(
                          "Add or edit your pages on " +
                            _vm._s(_vm.appName) +
                            "."
                        )
                      ]),
                      _c(
                        "gov-button",
                        {
                          attrs: { start: "", to: { name: "services-index" } }
                        },
                        [_vm._v("\n          Go to services\n        ")]
                      ),
                      _c("gov-section-break", { attrs: { size: "m" } })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.auth.canView("locations")
                ? _c(
                    "gov-grid-column",
                    { attrs: { width: "one-half" } },
                    [
                      _c("gov-heading", { attrs: { size: "l" } }, [
                        _vm._v("Locations")
                      ]),
                      _c("gov-body", [
                        _vm._v(
                          "View and edit service locations in the Borough."
                        )
                      ]),
                      _c(
                        "gov-button",
                        {
                          attrs: { start: "", to: { name: "locations-index" } }
                        },
                        [_vm._v("\n          Go to locations\n        ")]
                      ),
                      _c("gov-section-break", { attrs: { size: "m" } })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.auth.canView("referrals")
                ? _c(
                    "gov-grid-column",
                    { attrs: { width: "one-half" } },
                    [
                      _c("gov-heading", { attrs: { size: "l" } }, [
                        _vm._v("Referrals")
                      ]),
                      _c("gov-body", [
                        _vm._v(
                          "View and respond to referrals to your service(s)."
                        )
                      ]),
                      _c(
                        "gov-button",
                        {
                          attrs: { start: "", to: { name: "referrals-index" } }
                        },
                        [_vm._v("\n          Go to referrals\n        ")]
                      ),
                      _c("gov-section-break", { attrs: { size: "m" } })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.auth.canView("organisations")
                ? _c(
                    "gov-grid-column",
                    { attrs: { width: "one-half" } },
                    [
                      _c("gov-heading", { attrs: { size: "l" } }, [
                        _vm._v("Organisations")
                      ]),
                      _c("gov-body", [
                        _vm._v(
                          "Add or edit organisations on " +
                            _vm._s(_vm.appName) +
                            "."
                        )
                      ]),
                      _c(
                        "gov-button",
                        {
                          attrs: {
                            start: "",
                            to: { name: "organisations-index" }
                          }
                        },
                        [_vm._v("\n          Go to organisations\n        ")]
                      ),
                      _c("gov-section-break", { attrs: { size: "m" } })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.auth.canView("events")
                ? _c(
                    "gov-grid-column",
                    { attrs: { width: "one-half" } },
                    [
                      _c("gov-heading", { attrs: { size: "l" } }, [
                        _vm._v("Events")
                      ]),
                      _c("gov-body", [
                        _vm._v(
                          "Add or edit events on " + _vm._s(_vm.appName) + "."
                        )
                      ]),
                      _c(
                        "gov-button",
                        { attrs: { start: "", to: { name: "events-index" } } },
                        [_vm._v("\n          Go to events\n        ")]
                      ),
                      _c("gov-section-break", { attrs: { size: "m" } })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.auth.canView("users")
                ? _c(
                    "gov-grid-column",
                    { attrs: { width: "one-half" } },
                    [
                      _c("gov-heading", { attrs: { size: "l" } }, [
                        _vm._v("Users")
                      ]),
                      _c("gov-body", [
                        _vm._v("View, add and edit users in your organisation.")
                      ]),
                      _c(
                        "gov-button",
                        { attrs: { start: "", to: { name: "users-index" } } },
                        [_vm._v("\n          Go to users\n        ")]
                      ),
                      _c("gov-section-break", { attrs: { size: "m" } })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.auth.canView("reports")
                ? _c(
                    "gov-grid-column",
                    { attrs: { width: "one-half" } },
                    [
                      _c("gov-heading", { attrs: { size: "l" } }, [
                        _vm._v("Reports")
                      ]),
                      _c("gov-body", [
                        _vm._v(
                          " Download reports of activity on " +
                            _vm._s(_vm.appName) +
                            ". "
                        )
                      ]),
                      _c(
                        "gov-button",
                        { attrs: { start: "", to: { name: "reports-index" } } },
                        [_vm._v("\n          Go to reports\n        ")]
                      ),
                      _c("gov-section-break", { attrs: { size: "m" } })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.auth.canView("pages")
                ? _c(
                    "gov-grid-column",
                    { attrs: { width: "one-half" } },
                    [
                      _c("gov-heading", { attrs: { size: "l" } }, [
                        _vm._v("Pages")
                      ]),
                      _c("gov-body", [_vm._v("Manage pages on the platform.")]),
                      _c(
                        "gov-button",
                        { attrs: { start: "", to: { name: "pages-index" } } },
                        [_vm._v("\n          Go to pages\n        ")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.auth.canView("admin")
                ? _c(
                    "gov-grid-column",
                    { attrs: { width: "one-half" } },
                    [
                      _c("gov-heading", { attrs: { size: "l" } }, [
                        _vm._v("Admin")
                      ]),
                      _c("gov-body", [
                        _vm._v("Admin tools for maintaining the site.")
                      ]),
                      _c(
                        "gov-button",
                        { attrs: { start: "", to: { name: "admin-index" } } },
                        [_vm._v("\n          Go to admin\n        ")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.auth.canView("update requests")
                ? _c(
                    "gov-grid-column",
                    { attrs: { width: "one-half" } },
                    [
                      _c("gov-heading", { attrs: { size: "l" } }, [
                        _vm._v("Update Requests")
                      ]),
                      _c("gov-body", [
                        _vm._v("View, approve and reject update requests.")
                      ]),
                      _c(
                        "gov-button",
                        {
                          attrs: {
                            start: "",
                            to: { name: "update-requests-index" }
                          }
                        },
                        [_vm._v("\n          Go to update requests\n        ")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }